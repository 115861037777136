var bep = require('../configbep.json');

try {
  if (bep.APIBE_LO) {
    if (bep.APIBE_LO === "" || bep.APIBE_LO === " ") {
      bep.APIBE_LO = "//api." + window.location.hostname;
    }
  } else {
    bep.APIBE_LO = "//api." + window.location.hostname;
  }

  if (bep.APP_KEY) {
    if (bep.APP_KEY === '' || bep.APP_KEY === ' ') {
      bep.APP_KEY = '0';
    }
  } else {
    bep.APP_KEY = '0';
  }

  if (bep.USE_POPAPPROVE) {
    if (bep.USE_POPAPPROVE === '' || bep.USE_POPAPPROVE === ' ') {
      bep.USE_POPAPPROVE = '0';
    }
  } else {
    bep.USE_POPAPPROVE = '0';
  }

  if (bep.USE_POPACCEPT) {
    if (bep.USE_POPACCEPT === '' || bep.USE_POPACCEPT === ' ') {
      bep.USE_POPACCEPT = '0';
    }
  } else {
    bep.USE_POPACCEPT = '0';
  }

  if (bep.USE_POPREC) {
    if (bep.USE_POPREC === '' || bep.USE_POPREC === ' ') {
      bep.USE_POPREC = '0';
    }
  } else {
    bep.USE_POPREC = '0';
  }

  if (bep.port !== undefined) {
    console.log(bep.port);
  } else if (window.location.protocol === 'http:') {
    bep.port = ':8080';
  } else {
    bep.port = '';
  }
} catch (ex) {
  bep = { APIBE_LO: "//api." + window.location.hostname, APP_KEY: "0" };
  if (window.location.protocol === "http:") {
    bep.port = ":8080";
  } else {
    bep.port = '';
  }
  console.log(
    'ไปสร้างไฟล์ configbep.json ใน src ของ frontend หรือ ข้างๆ App.js',
  );
}

export default bep;
