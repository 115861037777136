import React, { useState, useEffect, createContext, Fragment } from 'react';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { Backdrop, CircularProgress } from '@material-ui/core';
import useSettingAndConfig from './hooks/useSettingAndConfig.js';
import { useAuth } from './features/Auth/Contexts/AuthProvider.js';
import ProtectedRoute from './features/Auth/components/Route/ProtectedRoute.js';
import Swal from 'sweetalert2';

import bep from './utils/bep.js';

const Navigation = React.lazy(() => import('./components/inc/inc-header'));
const Footer = React.lazy(() => import('./components/inc/inc-footer.js'));

const Home = React.lazy(() => import('./components/Home'));
const Auth = React.lazy(() => import('./pages/Auth/Auth.SignIn.js'));
const Error = React.lazy(() => import('./components/Sys.Error'));

/* LIS Pages */
const LabRequest = React.lazy(() => import('./components/Main.LabRequest'));
const MainLabV1 = React.lazy(() => import('./components/Main.Lab'));
const MainLabV2 = React.lazy(() => import('./pages/MainLab/Main.Lab'));
const Report = React.lazy(() => import('./components/Main.Report'));
const ReportHistoryV1 = React.lazy(() => import('./components/Report.History'));
const ReportHistoryV2 = React.lazy(
  () => import('./pages/ReportHistory/Report.History'),
);
const OnlineReportV1 = React.lazy(() => import('./components/Report.report'));
const OnlineReportV2 = React.lazy(
  () => import('./pages/OnlineReport/Report.OnlineReport.js'),
);
const CheckUp = React.lazy(() => import('./components/Main.Checkup'));
const ScanBarcode = React.lazy(() => import('./components/Main.ScanBarcode'));
const RiskManagement = React.lazy(
  () => import('./pages/RiskManagement/Main.RiskManagement'),
);
const MainLog = React.lazy(() => import('./pages/MainLog/Main.Log'));

/* QMS Pages */
const QMSregister = React.lazy(
  () => import('./components/QMS/pages/QMS.Register'),
);
const QMSkiosk = React.lazy(() => import('./components/QMS/pages/QMS.Kiosk'));
const QMSqueue = React.lazy(() => import('./components/QMS/pages/QMS.Queue'));
const QMSdashboardA = React.lazy(
  () => import('./components/QMS/pages/QMS.Dashboard-A'),
);
const QMSdashboardB = React.lazy(
  () => import('./components/QMS/pages/QMS.Dashboard-B'),
);
const QMSdashboardC = React.lazy(
  () => import('./components/QMS/pages/QMS.Dashboard-C'),
);
const QMSdashboardD = React.lazy(
  () => import('./components/QMS/pages/QMS.Dashboard-D'),
);
const QMSreport = React.lazy(() => import('./components/QMS/pages/QMS.Report'));

/* Setting Pages */
const SettingLIS = React.lazy(() => import('./components/Setting.Main'));
const SettingQMS = React.lazy(
  () => import('./components/QMS/pages/QMS.Setting'),
);
const Backup = React.lazy(() => import('./components/Sys.Backup'));
const ClientSetting = React.lazy(
  () => import('./components/Manage.ClientSetting'),
);
const UserLevel = React.lazy(
  () => import('./pages/UserLevel/Manage.UserLevel.js'),
);
const UserAccount = React.lazy(
  () => import('./pages/UserAccount/Manage.UserAccount.js'),
);

export const ENVfEnd = createContext(null);

const App = (props) => {
  const location = useLocation();

  const {
    userIsLoggedIn,
    userData,
    isSuperAdmin,
    permissionRouteLIS,
    permissionRouteQMS,
    permissionRouteSetting,
    verifyTokenAndPermission,
  } = useAuth();

  const {
    globalSettingMap,
    dashboardQMS,
    serverVersion,
    fetchSettingAndConfig,
  } = useSettingAndConfig();

  const [isPageLoading, setIsPageLoading] = useState(true);

  useEffect(() => {
    const getFirstSessionDATA = async () => {
      const { isValidToken, message, error } = await verifyTokenAndPermission();
      if (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: error.message,
        });
        return;
      }
      if (message) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: message,
        });
      }
      if (isValidToken) {
        await fetchSettingAndConfig();
      }
      setIsPageLoading(false);
    };

    getFirstSessionDATA();
  }, []);

  // verify token when route changes
  useEffect(() => {
    verifyTokenAndPermission();
  }, [location]);

  return (
    <Fragment>
      {isPageLoading ? (
        <Backdrop open={isPageLoading}>
          <CircularProgress thickness={5} size={70} className='text-light' />
        </Backdrop>
      ) : (
        <ENVfEnd.Provider
          value={{
            ...bep,
            userData,
            isSuperAdmin,
            globalSettingMap,
          }}
        >
          <React.Suspense
            fallback={
              <Modal show={true} centered>
                <Modal.Header>
                  <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h5>Page Loading...</h5>
                </Modal.Body>
              </Modal>
            }
          >
            <Switch>
              <Route
                exact
                path={[
                  '/qms/dashboardA',
                  '/qms/dashboardB',
                  '/qms/dashboardC',
                  '/qms/dashboardD',
                  '/qms/kiosk',
                ]}
                render={() => <></>}
              />
              <Route
                exact
                path='/*'
                render={() =>
                  userData && (
                    <Navigation
                      isSuperAdmin={isSuperAdmin}
                      permissionRouteLIS={permissionRouteLIS}
                      permissionRouteQMS={permissionRouteQMS}
                      permissionRouteSetting={permissionRouteSetting}
                      dashboardQMS={dashboardQMS}
                      user={
                        userData.prefix + userData.fname + ' ' + userData.mtno
                      }
                    />
                  )
                }
              />
            </Switch>
            <Switch>
              {/* Login Page */}
              <Route
                exact
                path='/signin'
                render={() =>
                  !userIsLoggedIn ? <Auth /> : <Redirect to='/' />
                }
              />

              {/* Home Page */}
              <ProtectedRoute exact path={['/', '/home']}>
                <Home
                  isSuperAdmin={isSuperAdmin}
                  permissionRouteLIS={permissionRouteLIS}
                  permissionRouteQMS={permissionRouteQMS}
                  permissionRouteSetting={permissionRouteSetting}
                  dashboardQMS={dashboardQMS}
                />
                <Footer serverVersion={serverVersion} />
              </ProtectedRoute>

              {/* LIS Route */}
              <ProtectedRoute exact path='/labrequest'>
                <LabRequest
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteLIS.canAccessLabRequest
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/mainlab/v1'>
                <MainLabV1
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={isSuperAdmin || permissionRouteLIS.canAccessMainLab}
                  canAccessReportHistory={
                    isSuperAdmin || permissionRouteLIS.canAccessReportHistory
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/report'>
                <Report
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={isSuperAdmin || permissionRouteLIS.canAccessReport}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/reportHistory/v1'>
                <ReportHistoryV1
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteLIS.canAccessReportHistory
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/checkup'>
                <CheckUp
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={isSuperAdmin || permissionRouteLIS.canAccessCheckup}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/onlineReport/v1'>
                <OnlineReportV1
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteLIS.canAccessHospitalNet
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/riskManagement'>
                <RiskManagement
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteLIS.canAccessRiskManagement
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/labrequest/scanbarcode'>
                <ScanBarcode
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteLIS.canAccessScanBarcode
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/mainlog'>
                <MainLog
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={isSuperAdmin || permissionRouteLIS.canAccessMainLog}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/mainlab'>
                <MainLabV2
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={isSuperAdmin || permissionRouteLIS.canAccessMainLab}
                  canAccessReportHistory={
                    isSuperAdmin || permissionRouteLIS.canAccessReportHistory
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/reportHistory'>
                <ReportHistoryV2
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteLIS.canAccessReportHistory
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/onlineReport'>
                <OnlineReportV2
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteLIS.canAccessHospitalNet
                  }
                />
              </ProtectedRoute>
              {/* LIS Route */}

              {/* QMS Route */}
              <ProtectedRoute exact path='/qms/register'>
                <QMSregister
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={isSuperAdmin || permissionRouteQMS.canAccessRegister}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/qms/kiosk'>
                <QMSkiosk
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={isSuperAdmin || permissionRouteQMS.canAccessKiosk}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/qms/queue'>
                <QMSqueue
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={isSuperAdmin || permissionRouteQMS.canAccessQueue}
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/qms/dashboardA'>
                <QMSdashboardA
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteQMS.canAccessDashboard
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/qms/dashboardB'>
                <QMSdashboardB
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteQMS.canAccessDashboard
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/qms/dashboardC'>
                <QMSdashboardC
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteQMS.canAccessDashboard
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/qms/dashboardD'>
                <QMSdashboardD
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteQMS.canAccessDashboard
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/qms/report'>
                <QMSreport
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={isSuperAdmin || permissionRouteQMS.canAccessReport}
                />
              </ProtectedRoute>
              {/* QMS Route */}

              {/* Setting Route */}
              <ProtectedRoute exact path='/setting'>
                <SettingLIS
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteSetting.canAccessSettingLIS
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/qms/setting'>
                <SettingQMS
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin || permissionRouteSetting.canAccessSettingQMS
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/account'>
                <UserAccount
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin ||
                    permissionRouteSetting.canAccessSettingAccount
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/backup'>
                <Backup
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin ||
                    permissionRouteSetting.canAccessSettingScript
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/clientsetting'>
                <ClientSetting
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={
                    isSuperAdmin ||
                    permissionRouteSetting.canAccessSettingClient
                  }
                />
              </ProtectedRoute>
              <ProtectedRoute exact path='/userlevel'>
                <UserLevel
                  userData={userData}
                  isSuperAdmin={isSuperAdmin}
                  isAuthZ={isSuperAdmin}
                />
              </ProtectedRoute>
              {/* Setting Route */}

              <Route exact component={Error} />
            </Switch>
          </React.Suspense>
        </ENVfEnd.Provider>
      )}
    </Fragment>
  );
};

export default App;
