import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth } from '../../Contexts/AuthProvider';

function ProtectedRoute({ children, ...rest }) {
  const { userIsLoggedIn } = useAuth();

  return (
    <Route
      {...rest}
      render={() => (userIsLoggedIn ? children : <Redirect to='/signin' />)}
    />
  );
}

export default ProtectedRoute;
