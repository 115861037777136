export const checkPermissionRoute = (userPermissionList) => {
  let permissionLIS = {
    canAccessMainLab: false,
    canAccessLabRequest: false,
    canAccessReport: false,
    canAccessReportHistory: false,
    canAccessCheckup: false,
    canAccessHospitalNet: false,
    canAccessRiskManagement: false,
    canAccessScanBarcode: false,
    canAccessMainLog: false,
  };
  let permissionQMS = {
    canAccessRegister: false,
    canAccessKiosk: false,
    canAccessQueue: false,
    canAccessDashboard: false,
    canAccessReport: false,
  };
  let permissionSetting = {
    canAccessSettingLIS: false,
    canAccessSettingQMS: false,
    canAccessSettingAccount: false,
    canAccessSettingScript: false,
    canAccessSettingClient: false,
  };

  for (let permission of userPermissionList) {
    switch (permission) {
      // LIS
      case 'p_lis_mainlab':
        permissionLIS.canAccessMainLab = true;
        break;
      case 'p_lis_labrequest':
        permissionLIS.canAccessLabRequest = true;
        break;
      case 'p_lis_report':
        permissionLIS.canAccessReport = true;
        break;
      case 'p_lis_reporthistory':
        permissionLIS.canAccessReportHistory = true;
        break;
      case 'p_lis_checkup':
        permissionLIS.canAccessCheckup = true;
        break;
      case 'p_lis_hospitalnet':
        permissionLIS.canAccessHospitalNet = true;
        break;
      case 'p_lis_riskmanagement':
        permissionLIS.canAccessRiskManagement = true;
        break;
      case 'p_lis_scanbarcode':
        permissionLIS.canAccessScanBarcode = true;
        break;
      case 'p_lis_mainlog':
        permissionLIS.canAccessMainLog = true;
        break;
      // QMS
      case 'p_qms_register':
        permissionQMS.canAccessRegister = true;
        break;
      case 'p_qms_kiosk':
        permissionQMS.canAccessKiosk = true;
        break;
      case 'p_qms_queue':
        permissionQMS.canAccessQueue = true;
        break;
      case 'p_qms_dashboard':
        permissionQMS.canAccessDashboard = true;
        break;
      case 'p_qms_report':
        permissionQMS.canAccessReport = true;
        break;
      // Setting
      case 'p_setting_lis':
        permissionSetting.canAccessSettingLIS = true;
        break;
      case 'p_setting_qms':
        permissionSetting.canAccessSettingQMS = true;
        break;
      case 'p_setting_account':
        permissionSetting.canAccessSettingAccount = true;
        break;
      case 'p_setting_script':
        permissionSetting.canAccessSettingScript = true;
        break;
      case 'p_setting_client':
        permissionSetting.canAccessSettingClient = true;
        break;
      default:
        break;
    }
  }

  return {
    permissionLIS,
    permissionQMS,
    permissionSetting,
  };
};
